<template>
  <v-container class="fill-height">
    <v-row class="d-flex align-center justify-center">
      <v-col
        cols="12"
        md="5"
        lg="4"
        xl="3"
        v-for="dp_link in dp_links"
        :key="dp_link.text"
      >
        <v-card
          class="ma-4"
          rounded="lg"
          elevation="1"
          :prepend-icon="dp_link.icon"
          :disabled="dp_link.disabled"
        >
          <v-img :src="dp_link.bgImage" cover height="250"></v-img>
          <template v-slot:title>
            {{ dp_link.text }}
          </template>
          <v-card-text>
            <p>{{ dp_link.description }}</p>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              variant="flat"
              color="secondary"
              class="text-white"
              size="large"
              block
              @click="$router.push(dp_link.href)"
              >Go</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import endpoints from "../utils/endpoints";

export default {
  name: "ApplicationContent",

  data: () => ({
    dp_links: [],
  }),

  created() {
    fetch(endpoints.dynamicListNavbarItems())
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al cargar el JSON");
        }
        return response.json();
      })
      .then((data) => {
        this.dp_links = data;
      })
      .catch((error) => {
        console.error("Error al cargar el JSON:", error.message);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
